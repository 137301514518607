import $ from "jquery";

$(document).on('click', '.js-get-user-detail img', function(e){
  e.preventDefault();

  $(this).closest(".js-get-user-detail").trigger('click');
});

$(document).on('click', '.js-get-user-detail', function (e) {
  e.preventDefault();

  var userId = $(this).data("user-id");

  $.ajax({
    url: '/user_managements/detail',
    method: 'GET',
    data: {user_id: userId, 'authenticity_token': $('meta[name="csrf-token"]').attr('content')}
  }).done(function (data) {
    var data = data.object;

    $('.js-detail-user-modal').find(".js-user-name").html('').html(data.name);
    $('.js-detail-user-modal').find(".js-user-age").html('').html(data.age);
    $('.js-detail-user-modal').find(".js-user-gender").html('').html(data.gender);
    $('.js-detail-user-modal').find(".js-occupation").html('').html(data.occupation.name);
    $('.js-detail-user-modal').find(".js-employment-status").html('').html(data.employment_status);
    $('.js-detail-user-modal').find(".js-type-of-work").html('').html(data.type_of_work);
    $('.js-detail-user-modal').find(".js-working-hours-per-week").html('').html(data.working_hours_per_week);
    $('.js-detail-user-modal').find(".js-day-off").html('').html(data.day_off);

    if (data.occupation.image_url != undefined) {
      $('.js-detail-user-modal').find(".js-occupation-image").html('').html(`<img src="${data.occupation.image_url}">`);
    }

    console.log(data.favorite_activity);
    if (data.favorite_activity.length > 0) {
      var favorite_activity_str = "";
      $.each(data.favorite_activity, function (i, v) {
        favorite_activity_str += `<div class="content-box text-center col-3">
                              <div class="favorite-box-title">${v.name}</span>
                              <img src="${v.image_url}" />
                              </div>
                            </div>`
      });
    }
    $('.js-detail-user-modal').find(".js-favorite-data").html('').html(favorite_activity_str);

    $(".js-detail-user-modal").modal("show");
  });
});

$(document).on('click', '.switch', function(){
  var checkbox = $(this).find('.js-ai-use-slider');

  if (checkbox.is(':checked')) {
    // Uncheck if currently checked
    checkbox.prop('checked', false);
  } else {
    // Check if currently unchecked
    checkbox.prop('checked', true);
  }
})

$(document).on('change', '.js-ai-use-slider', function(){
  var userId = $(this).closest('.item-row').data('user-id');
  var inputValue = 0;
  if ($(this).is(':checked')) {
    // Checkbox is checked
    inputValue = 1;
  }
  $("#overlay").fadeIn(300);

  $.ajax({
    url: '/user_managements/update-ai-status',
    method: 'POST',
    data: { user_id: userId,
      value: inputValue,
      'authenticity_token': $('meta[name="csrf-token"]').attr('content') }
  }).done(function(res) {
    $("#overlay").fadeOut(300);
  })
})

// $(document).on('click', '.js-get-user-detail', function (e) {
//   e.preventDefault();
//   var url = $(this).data('redirect-url');
//   location.href = url;
// });